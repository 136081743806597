import React, { useEffect, useState } from 'react';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
import { Button, Row, Col, Toast } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './global.css';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import AuthAPI from './service/authAPI';
import AuthContext from './contexts/AuthContext';
import axios from 'axios';

// ----------------------------------------------------------------------
AuthAPI.setup();

export default function App() {
  const [user, setUser] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(AuthAPI.isAuthenticated());
  const [notifications, setNotifications] = useState([]);
  const contextValue = { isAuthenticated, setIsAuthenticated, user, setUser, notifications, setNotifications };

  useEffect(() => {
    const id = setInterval(fetchNotifications, 5000);
    return () => clearInterval(id);
  }, []);

  const fetchNotifications = async () => {
    const ApiUrl = `${process.env.REACT_APP_PUBLIC_API}/notifications`;
    try {
      const data = await axios.get(ApiUrl).then((response) => response.data);
      setNotifications(data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <AuthContext.Provider value={contextValue}>
        <ThemeProvider>
          <ScrollToTop />
          <BaseOptionChartStyle />
          <Router />
        </ThemeProvider>
      </AuthContext.Provider>
    </>
  );
}
