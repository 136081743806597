import React, { useContext, useState } from 'react';
import AnnonceContext from '../../../../contexts/AnnonceContext';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import DaySelector from './Forms/DaySelector';

import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { useEffect } from 'react';
export default function Step1() {
  const { formAnnonce, setFormAnnonce, date, setDate } = useContext(AnnonceContext);
  const [isLost, setIsLost] = useState();
  const handleChangeDate = (newDate) => {
    setDate(newDate);
    setFormAnnonce({ ...formAnnonce, date: newDate.toISOString() });
  };

  useEffect (() => {
    setIsLost(formAnnonce.status === "PERDU" ? true : false);
  },[])

  const handleStatus = (e) => {
    setFormAnnonce({ ...formAnnonce, status: e.target.value, origin: e.target.value });
    setIsLost(e.target.value === "PERDU" ? true : false);
  };

  return (
    <>
      <Stack spacing={2}>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="status-radio-buttons-group"
            name="status-radio-buttons-group"
            value={formAnnonce.status}
            onChange={handleStatus}
          >
            <FormControlLabel
              value="TROUVÉ"
              control={<Radio checkedIcon={<SentimentSatisfiedAltIcon />} />}
              label="Trouvé"
            />
            <FormControlLabel
              value="PERDU"
              control={<Radio checkedIcon={<SentimentVeryDissatisfiedIcon />} />}
              label="Perdu"
            />
          </RadioGroup>
        </FormControl>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            label="Quand ?"
            inputFormat="dd/MM/yyyy"
            value={date}
            onChange={(newDate) => {
              handleChangeDate(newDate);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <DaySelector setDate={setDate} date={date} />

        {isLost && 
        
        <TextField
      
        required
        id="contact"
        label="Tél ou Email Personne à Contacter"
        defaultValue={formAnnonce.contact}
        onChange={(e) => setFormAnnonce({ ...formAnnonce, contact: e.target.value })}
      />
        
        }
      </Stack>
    </>
  );
}
