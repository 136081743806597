import React from 'react';
import {
  Box,
  Container,
  Button,
  Drawer,
  Typography,
  Avatar,
  Stack,
  Grid,
  Card,
  List,
  ListItemText,
  Chip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Label from 'src/components/Label';
import { ColorPreview } from 'src/components/color-utils';
import { display } from '@mui/system';
export default function Adetails({ annonce }) {
  const url = process.env.REACT_APP_PUBLIC_URL;
  const { id, title, filePath, status = 1, category, annonceAttributes, stockage } = annonce;
  const boxName = null !== stockage ? stockage.boxName : 'pas de stockage';
  const image = `${url}/images/annonces/${filePath}`;
  const publicAttributes = annonceAttributes.filter((att) => att.isSecret === false);
  const secretAttributes = annonceAttributes.filter((att) => att.isSecret === true);
 

  const ProductImgStyle = styled('img')({
    top: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
  });

  return (
    <Container sx={{ display: 'flex', flexDirection: 'column', alignContent: 'center' }}>
      <Box pl={5} pt={2}>
        <Stack direction="row">
          <Typography variant="h3" pb={3}>
            {category.name} {id}
          </Typography>
        </Stack>
        <Typography variant="h5" pb={3}>
          <Chip label={`Stocké ${boxName}`} color="primary" size="30px" />
        </Typography>
        <Stack direction="row" spacing={1} pb={3}>
          {publicAttributes.map((attribute) => {
            return (
              <>
                {attribute.uiType === "colorcheckbox" ? (
                  <>
                    <Chip
                      key={attribute.id}
                      label={` ${attribute.uiLabel} `}
                      sx={{
                        color: "#fff",
                        backgroundColor: attribute.uiValue,
                        marginBottom: "3px",
                        marginRight: "2px",
                      }}
                    />
                  </>
                ) : (
                  <>
                    <Chip
                      key={attribute.id}
                      variant="outlined"
                      label={`${attribute.attributeValue}`}
                      sx={{
                        color: "#1b9098",
                      }}
                    />
                  </>
                )}
              </>
            );
          })}
        </Stack>

        <Card sx={{ width: '70%', border: '1px solid #ccc' }}>
          <Box sx={{ pt: '100%', position: 'relative' }}>
            {status && (
              <Label
                variant="filled"
                color={(status === 'sale' && 'error') || 'info'}
                sx={{
                  zIndex: 9,
                  top: 16,
                  right: 16,
                  position: 'absolute',
                  textTransform: 'uppercase',
                }}
              >
                {status}
              </Label>
            )}
            <ProductImgStyle alt={category.name} src={image} />
          </Box>

          <Stack
            sx={{
              padding: '5px',
              backgroundColor: '#D0E8EA',
            }}
          >
            <Stack pl={5}>
              <Typography variant="h4">Détails Secrets</Typography>
              {secretAttributes.map((attribute) => {
                return (
                  <Stack>
                    <List disablePadding>
                      <ListItemText primary={attribute.attribute} secondary={attribute.attributeValue} />
                    </List>
                  </Stack>
                );
              })}
            </Stack>
          </Stack>
        </Card>
      </Box>
    </Container>
  );
}
