import React, { createContext, useEffect, useState } from 'react';
import axios from 'axios';
import CustomStepper from './FormComponents/CustomStepper';
import { ToastContainer, toast } from 'react-toastify';
import AnnonceContext from '../../../contexts/AnnonceContext';
import Page from 'src/components/Page';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

const Publier = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [formAnnonce, setFormAnnonce] = useState({
    origin: 'TROUVÉ',
    status: 'TROUVÉ',
    ville: 'Les Vieilles Charrues, Carhaix',
    placeDescription: 'Les Vieilles Charrues, Rue de Persivien, Carhaix-Plouguer, France',
  });
  const [currentCategory, setCurrentCategory] = useState();
  const [formAttributes, setFormAttributes] = useState([]);
  const [imageUpload, setImageUpload] = useState();
  const [date, setDate] = useState(new Date().toISOString());

  let navigate = useNavigate();

  const notifySuccess = (message) => {
    toast.success(message, {
      position: 'top-center',
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      onClose: () => {
        navigate('/dashboard/products/trouves');
      },
    });
  };
  useEffect(() => {
    console.log(formAnnonce);
    setFormAnnonce({
      ...formAnnonce,

      annonceAttributes: formAttributes,
      date: date,
    });
  }, [formAttributes]);

  const uploadImg = async (annonceId) => {
    const formData = new FormData();

    formData.append('file', imageUpload);
    try {
      await axios.post(process.env.REACT_APP_PUBLIC_API + `/annonces/${annonceId}/image`, formData);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async () => {
    console.log(formAnnonce);
    try {
      const response = await axios.post(process.env.REACT_APP_PUBLIC_API + '/annonces', formAnnonce);

      const annonceId = response.data.id;

      uploadImg(annonceId);
      notifySuccess('Annonce ajoutée avec succès');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <AnnonceContext.Provider
      value={{
        formAnnonce: formAnnonce,
        setFormAnnonce: setFormAnnonce,

        currentCategory: currentCategory,

        setCurrentCategory: setCurrentCategory,
        formAttributes: formAttributes,
        setFormAttributes: setFormAttributes,
        handleSubmit: handleSubmit,
        imageUpload: imageUpload,
        setImageUpload: setImageUpload,
        activeStep: activeStep,
        setActiveStep: setActiveStep,
        date: date,
        setDate: setDate,
      }}
    >
      <Page>
        <ToastContainer />
        <CustomStepper />
      </Page>
    </AnnonceContext.Provider>
  );
};

export default Publier;
