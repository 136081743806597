import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Avatar, Grid, IconButton, Stack, Typography } from '@mui/material';
import AnnonceContext from '../../../../../contexts/AnnonceContext';

export default function CategorySelector() {
  const { formAnnonce, setFormAnnonce, setCurrentCategory, setFormAttributes } = useContext(AnnonceContext);
  const baseUrl = process.env.REACT_APP_BASEURL;
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_PUBLIC_API}/categories?parent=1&order[sort]=asc`;

    axios.get(apiUrl).then((resp) => {
      setCategories(resp.data);
    });
  }, []);

  const handleCategory = (category) => {
    setFormAnnonce({
      ...formAnnonce,
      category: `api/categories/${category.id}`,
    });

    setCurrentCategory(category);
    setFormAttributes([]);
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid
          item
          md={12}
          sx={{
            display: 'flex',
            justifyContent: 'space-start',
            flexWrap: 'wrap',
          }}
        >
          {/* <Stack 
              sx={{ marginRight: 5 }}
              direction="row"
              spacing={4}
              marginBottom={4}
              
            > */}
          {categories.map((category) => {
            return (
              <Stack key={category.id} sx={{ width: '100px' }}>
                <IconButton onClick={() => handleCategory(category)}>
                  <Avatar alt="" src={`/pictos/${category.image}.svg`} sx={{ width: 68, height: 68 }} />
                </IconButton>
                <Typography sx={{ fontSize: 12, textAlign: 'center' }}>{category.name}</Typography>
              </Stack>
            );
          })}
          {/* </Stack> */}
        </Grid>
      </Grid>
    </>
  );
}
