import { TextField, Typography } from "@mui/material";
import React, { useContext } from "react";
import AnnonceContext from "../../../../contexts/AnnonceContext";

import Upload from "./Forms/upload";

export default function Step3() {
  const { formAnnonce, setFormAnnonce } = useContext(AnnonceContext);

  return (
    <>
      <Typography variant="body1">
       Cliquer pour ajouter ou prendre une photo
      </Typography>

      <Upload />

     
    </>
  );
}
