import { Button, Divider, Stack, TextField } from '@mui/material';
import React, { Fragment, useContext, useEffect, useState } from 'react';

import FilterSelector from './FilterSelector';
import axios from 'axios';
import FilterSelectorAttributes from './FilterSelectorAttributes';
import { Box } from '@mui/system';
import { FoundContext } from 'src/pages/Products';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
export default function AnnonceSelector({ customerId }) {
  const {
    selectedCategory,
    setSelectedCategory,
    filteredAttributes,
    setFilteredAttributes,
    date,
    setDate,
    numObject,
    setNumObject,
    email,
    setEmail,
    name,
    setName,
  } = useContext(FoundContext);
  const [categories, setCategories] = useState([]);
  const [dateSelected, setDateSelected] = useState(new Date());

  const handleChangeDate = (newDate) => {
    setDate(newDate);
    setDateSelected(newDate);
  };

  const handleResetDate = () => {
    setDate(false);
  };

  useEffect(() => {
    const apiUrl = process.env.REACT_APP_PUBLIC_API + '/categories?parent=1&order[sort]=asc';

    axios.get(apiUrl).then((resp) => {
      setCategories(resp.data);
    });
  }, []);
  const day = new Date();
  const [alignment, setAlignment] = useState('web');
  const filteredCategory = selectedCategory ? categories.filter((a) => a.id === selectedCategory) : null;
  const handleChangeDay = (event, newAlignment) => {
    if (event.target.value === 'reset') {
      handleResetDate();
      setAlignment(newAlignment);
    } else {
      day.setDate(day.getDate() - event.target.value);
      setAlignment(newAlignment);
      setDate(day);
    }
  };
  return (
    <Box>
      <Stack sx={{ 'flex-direction': { sm: 'column', md: 'row' } }} spacing={2} mb={1}>
        <TextField
          label="Numero d'objet"
          onChange={(e) => {
            setNumObject(e.target.value);
          }}
        />
        <TextField
          label="email"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <TextField
          label="Nom"
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
      </Stack>

      <Stack sx={{ 'flex-direction': { sm: 'column', md: 'row' } }} spacing={2}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            label="Quand ?"
            inputFormat="dd/MM/yyyy"
            value={dateSelected}
            onChange={(newDate) => {
              handleChangeDate(newDate);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>

        <ToggleButtonGroup
          sx={{ size: { sm: 'small', md: 'medium' } }}
          color="primary"
          value={alignment}
          exclusive
          onChange={handleChangeDay}
        >
          <ToggleButton variant="contained" value={0}>
            Aujourd'hui
          </ToggleButton>
          <ToggleButton value={1}>Hier</ToggleButton>
          <ToggleButton value={2}>Avant-hier</ToggleButton>
          <ToggleButton value={3}>il y' a 3 jours</ToggleButton>
          <ToggleButton value="reset">toutes les dates</ToggleButton>
        </ToggleButtonGroup>
      </Stack>

      <Box
        sx={{
          marginBottom: '20px',
          display: 'inline-flex',
          justifyContent: 'start',
          height: 'auto',
          // backgroundColor: 'rgba(81,73,192,0.3)',

          width: '100%',
        }}
      >
        {' '}
        <Divider />
        <Stack sx={{ 'flex-direction': { xs: 'column', sm: 'column', md: 'row' }
        , mr:  "5px" }}>
          <FilterSelector
            label="Catégories"
            selectedValue={selectedCategory}
            setSelectedValue={setSelectedCategory}
            dataFilters={categories}
            setFilteredAttributes={setFilteredAttributes}
            filteredAttributes={filteredAttributes}
          />

          <Stack sx={{ 'flex-direction': { xs: 'column', sm: 'column', md: 'row' }, marginTop: 0 }}>
            {filteredCategory &&
              filteredCategory.map((data, key) => {
                return (
                  <Fragment key={key}>
                    {data.attribute.map((attribute, key) => {
                      return attribute.isSecret !== true ? (
                        <FilterSelectorAttributes
                          key={key}
                          filteredAttributes={filteredAttributes}
                          setFilteredAttributes={setFilteredAttributes}
                          label={attribute.name}
                          dataFilters={attribute.attributes}
                          ui={attribute.ui}
                          uiLabel={attribute.uiLabel}
                        />
                      ) : (
                        <></>
                      );
                    })}
                  </Fragment>
                );
              })}
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
}
