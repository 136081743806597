import * as Yup from 'yup';
import { useContext, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { Link, Stack, Checkbox, TextField, IconButton, InputAdornment, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../../../components/Iconify';
import AuthAPI from 'src/service/authAPI';
import AuthContext from 'src/contexts/AuthContext';
import { Form } from 'react-bootstrap';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const { setIsAuthenticated } = useContext(AuthContext);

  const [credentials, setCredentials] = useState({
    username: '',
    password: '',
  });

  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await AuthAPI.authenticate(credentials);
      setIsAuthenticated(true);

      setError('');
      setIsSubmitting(true);
      navigate('/dashboard');
    } catch (error) {
      console.error(error);
      setError('erreur de connexion');
    }
  };
  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Veuillez saisir un email valide').required('Email est obligatoire'),
    password: Yup.string().required('mot de passe obligatoire'),
  });

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const handleChange = (e) => {
    const value = e.currentTarget.value;
    const name = e.currentTarget.name;
    setCredentials({ ...credentials, [name]: value });
  };

  return (
    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
      <Stack spacing={3}>
        <TextField fullWidth autoComplete="username" type="email" label="Email address" 
         name="username"
         id="username"
        onChange={handleChange}
        value={credentials.username}
        />

        <TextField
          fullWidth
          autoComplete="current-password"
          type={showPassword ? 'text' : 'password'}
          label="Password"
         
          value={credentials.password}
       
       
          name="password"
          id="password"
          onChange={handleChange}


          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleShowPassword} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <LoadingButton
        sx={{ marginTop: '20px' }}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        Connexion
      </LoadingButton>
    </Form>
  );
}
