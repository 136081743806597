import React, { useEffect, useState } from 'react';
import Page from 'src/components/Page';

import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { fDateTime } from '../utils/formatTime';
import { Button, Container, Stack } from '@mui/material';

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Page>
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    </Page>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function CustomPaginationActionsTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const [currentConvs, setCurrentConvs] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    fetchConversations();
  }, []);

  const fetchConversations = async () => {
    try {
      const data = await axios
        .get(`${process.env.REACT_APP_PUBLIC_API}/conversations?annonce.customer=5`)
        .then((response) => response.data);

      setRows(data);
      setCurrentConvs(data);
    } catch (error) {
      console.error(error);
    }
  };
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - currentConvs.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getLastUserMessage = (messages) => {
    let filtered = messages.filter((message) => message.customerAuthor === null);

    if (filtered[filtered.length - 1]) {
      return filtered[filtered.length - 1].content;
    } else {
      return [];
    }
  };

  const handleNotResponded = () => {
    let notResponded = rows.filter((conv) => conv.isPending === true);

    setCurrentConvs(notResponded);
  };

  const handleResponded = () => {
    let responded = rows.filter((conv) => conv.isPending === false);

    // let responded = lastMessage.filter((conv) => {
    //   console.log(conv.messages.findLast((m) => m.customerAuthor.id === 2));
    //   return conv.messages.findLast((m) => m.customerAuthor.id === 2);
    // });
    // console.log(responded);
    setCurrentConvs(responded);
  };

  const handleGoToAds = (id) => {
    navigate(`/dashboard/products/${id}`);
  };

  return (
    <Container>
      <Stack direction="row" spacing={2}>
        <Button variant="outlined" onClick={handleNotResponded}>
          Non Répondus
        </Button>
        <Button variant="outlined" onClick={handleResponded}>
          Répondus
        </Button>
      </Stack>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableBody>
            {(rowsPerPage > 0
              ? currentConvs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : currentConvs
            ).map((conv) => (
              <TableRow
                key={conv.id}
                onClick={() => {
                  handleGoToAds(conv.annonce.id);
                }}
              >
                <TableCell component="th" scope="row" style={{ width: 100 }}>
                  <img
                    width="100px"
                    src={`${process.env.REACT_APP_PUBLIC_URL}/images/annonces/${conv.annonce.filePath}`}
                  />
                  {conv.annonce.category.name} {conv.annonce.id}
                </TableCell>

                <TableCell style={{ width: 300 }}>{getLastUserMessage(conv.messages)}</TableCell>
                <TableCell style={{ width: 160 }}>{fDateTime(conv.createdAt)}</TableCell>
                <TableCell style={{ width: 160 }}>{conv.author.email}</TableCell>
                <TableCell style={{ width: 160 }} align="right">
                  {conv.messages.length}
                </TableCell>
              </TableRow>
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'Toutes', value: -1 }]}
                colSpan={3}
                count={currentConvs.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'lignes par page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Container>
  );
}
