import { Box, Button, Typography } from '@mui/material';
import React, { useContext } from 'react';

import AnnonceContext from '../../../../contexts/AnnonceContext';
import BoxSelector from './Forms/BoxSelector';

export default function Step5() {
  const { handleSubmit, formAnnonce } = useContext(AnnonceContext);

  return (
    <>
     {
      formAnnonce.status === "TROUVÉ" ? (
        <Box pb={5}>
        <BoxSelector />
      </Box>

      ) : (
        <Typography variant="h5">
          Pas de Stockage pour les Objets Perdus
        </Typography>
      )

    
     }
     
      <Button variant="contained" color="primary" onClick={handleSubmit}>
        Publiez votre annonce
      </Button>
    </>
  );
}
