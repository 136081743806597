import React from "react";
import { Box, Paper, Stack } from "@mui/material";
import { styled } from '@mui/material/styles';
export const CategoriesBox = ({ data }) => {

  const baseUrl = process.env.REACT_APP_BASEURL;
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
    return (
  <Stack direction="row" spacing={2}>

    {data.map((stockage) => {
      return (
        <Item key={stockage.id}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 60,
              height: 60,
              backgroundImage: `url(${baseUrl + "/pictos/box.svg"})`,
              backgroundSize: "cover",
              "&:hover": {
                opacity: [0.9, 0.8, 0.7],
              },
            }}
          > <img src={`/pictos/${stockage.category.image}.svg`} alt="box" width={30} />
          
          </Box>{stockage.category.name}
        </Item>
      );    
    })}
  </Stack>
    );
};
