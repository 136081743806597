import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, SvgIcon } from '@mui/material';
import React, { useState, useEffect, useContext } from 'react';
import AnnonceContext from 'src/contexts/AnnonceContext';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { SwatchesPicker } from 'react-color';
export default function Colorcheckbox({ data, parent, isSecret }) {
  const { formAttributes, setFormAttributes } = useContext(AnnonceContext);
  const [value, setValue] = useState(false);

  function ColorIconChecked({ color }) {
    return (
      <CheckBoxIcon
        sx={{
          color: { color },
        }}
      />
    );
  }

  function ColorIconUnchecked({ color }) {
    return (
      <CheckBoxOutlineBlankIcon
        sx={{
          color: { color },
        }}
      />
    );
  }

  const [showColor, setShowColor] = useState(false);

  useEffect(() => {
    const attr = formAttributes.filter((att) => att.attribute === parent.name);
    if (attr.length > 0) {
      setValue(attr[0].attributeValue);
    }
  }, [formAttributes]);

  const [otherColor, setOtherColor] = useState("");
  return (
    <FormControl>
      <RadioGroup
        row
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={value ? value : ''}
        onChange={(e) => {
          if (e.target.value === 'Autre') {
            setShowColor(true);
          } else {
            setShowColor(false);
          }

          setFormAttributes((formAttributes) => [
            ...formAttributes.filter((att) => att.attribute !== parent.name),
            {
              attribute: parent.name,
              attributeValue: e.target.value,
              isSecret: isSecret,
              uiLabel: parent.uiLabel,
              uiType: parent.ui,
              uiValue: e.target.name,
            },
          ]);
        }}
      >
        {data.map((color, index) => (
          <FormControlLabel
            key={index}
            value={color.name}
            name={color.value}
            control={
              <Radio
                checkedIcon={<ColorIconChecked color={color.name !=="Autre" ? color.value : otherColor } checked={true} />}
                icon={<ColorIconUnchecked color={color.value} checked={false} />}
              />
            }
            label={color.name}
          />
        ))}
      </RadioGroup>
      {showColor && <SwatchesPicker width="500px" 
      name="Autre"
      onChange={(color, e) => {
        
        setOtherColor(color.hex);
        
        setFormAttributes((formAttributes) => [
          ...formAttributes.filter((att) => att.attribute !== parent.name),
          {
            attribute: parent.name,
            attributeValue: "Autre",
            isSecret: isSecret,
            uiLabel: parent.uiLabel,
            uiType: parent.ui,
            uiValue: color.hex,
          },
        ]);

        setShowColor(false);
      
      }} />
      }
    </FormControl>
  );
}
