import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';

import { Autocomplete, TextField } from '@mui/material';
import AnnonceContext from 'src/contexts/AnnonceContext';

export default function BoxSelector() {
  const { formAnnonce, setFormAnnonce } = useContext(AnnonceContext);
  const [options, setOptions] = useState([]);
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_PUBLIC_API}/stockages?motherBox=1`).then((res) => {
      setOptions(res.data);
    });
  }, []);

 
  return (
    <>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={options}
        getOptionLabel={(option) => option.boxName}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Boite" />}
        onChange={(evt, val) => {
          setFormAnnonce({ ...formAnnonce, stockage: `api/stockages/${val.id}` });
        }}
      />
    </>
  );
}
