import { Autocomplete, TextField } from '@mui/material';
import React, { useContext, useState } from 'react';
import AnnonceContext from '../../../../../contexts/AnnonceContext';

export default function SelectField({ name, data, larg = 200, isSecret = false }) {
  const { setFormAttributes } = useContext(AnnonceContext);
  const [sousSelect, setSousSelect] = useState(false);
  const [isChild, setIsChild] = useState(false);

  data.sort(function (a, b) {
    if (a.name < b.name) {
      return -1;
    } else {
      return 1;
    }
  });

  return (
    <>
      <Autocomplete
        disablePortal
        id="combo-box"
        options={data}
        getOptionLabel={(option) => (option.label ? option.label : option.name)}
        onChange={(evt, val) => {
          if (val !== null) {
            if (val.attributes.length > 0) {
              setSousSelect(val);
              setIsChild(true);
            }

            setFormAttributes((selectAttributes) => [
              ...selectAttributes.filter((att) => att.attribute !== name),
              {
                attribute: name,
                attributeValue: val.name,
                isSecret: isSecret,
                uiLabel: data.uiLabel,
              },
            ]);
          } else {
            setIsChild(false);
          }
        }}
        sx={{ width: larg }}
        renderInput={(params) => <TextField {...params} label={name} />}
      />

      {isChild && (
        <Autocomplete
          disablePortal
          id="combo-box-sousselect"
          options={sousSelect.attributes}
          getOptionLabel={(option) => (option.label ? option.label : option.name)}
          onChange={(evt, val) => {
            if (val !== null) {
              setFormAttributes((selectAttributes) => [
                ...selectAttributes.filter((att) => att.attribute !== `${name}option`),
                {
                  attribute: `${name}option`,
                  attributeValue: val.name,
                  isSecret: isSecret,
                  uiLabel: val.uiLabel,
                },
              ]);
            }
          }}
          sx={{ width: larg }}
          renderInput={(params) => <TextField {...params} label="selection" />}
        />
      )}
    </>
  );
}
