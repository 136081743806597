import React, { useEffect, useState } from 'react';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import axios from 'axios';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const theme = useTheme();
  const [data, setData] = useState([]);
  const [value1, setValue1] = useState([]);

  useEffect(() => {
    const apiUrl = process.env.REACT_APP_PUBLIC_API + '/a_stats';

    axios.get(apiUrl).then((resp) => {
      const allData = resp.data;
      setData(allData);
      setValue1(allData[0].values);
    });
  }, []);


  return (
    <Page title="Dashboard">
      {data.length > 0 && (
        <Container maxWidth="xl">
          <Typography variant="h4" sx={{ mb: 5 }}>
            Bienvenue
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary title="Nombres d'objets trouvés" total={205} icon={'ant-design:android-filled'} />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary title="New Users" total={1352831} color="info" icon={'ant-design:apple-filled'} />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="Item Orders"
                total={1723315}
                color="warning"
                icon={'ant-design:windows-filled'}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary title="Bug Reports" total={234} color="error" icon={'ant-design:bug-filled'} />
            </Grid>

            <Grid item xs={12} md={6} lg={8}>
              <AppWebsiteVisits
                title="Stats objets trouvés"
                subheader=""
                chartLabels={data[0].labels}
                chartData={value1}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <AppCurrentVisits
                title="Current Visits"
                chartData={[
                  { label: 'America', value: 4344 },
                  { label: 'Asia', value: 5435 },
                  { label: 'Europe', value: 1443 },
                  { label: 'Africa', value: 4443 },
                ]}
                chartColors={[
                  theme.palette.primary.main,
                  theme.palette.chart.blue[0],
                  theme.palette.chart.violet[0],
                  theme.palette.chart.yellow[0],
                ]}
              />
            </Grid>
          </Grid>
        </Container>
      )}
    </Page>
  );
}
