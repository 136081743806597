import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import AnnonceContext from "../../../../../contexts/AnnonceContext";

export default function DaySelector() {
  const { formAnnonce, setFormAnnonce, setDate } = React.useContext(
    AnnonceContext
  );

  const [alignment, setAlignment] = React.useState("web");
  const day = new Date();

  const handleChange = (event, newAlignment) => {
    day.setDate(day.getDate() - event.target.value);
    setAlignment(newAlignment);
    setDate(day);
    setFormAnnonce({ ...formAnnonce, date: day.toISOString() });
  };

  return (
    <ToggleButtonGroup
   
      color="primary"
      value={alignment}
      exclusive
      onChange={handleChange}
    >
      <ToggleButton    variant="contained" value={0}>Aujourd'hui</ToggleButton>
      <ToggleButton value={1}>Hier</ToggleButton>
      <ToggleButton value={2}>Avant-hier</ToggleButton>
      <ToggleButton value={3}>il y' a 3 jours</ToggleButton>
      
    </ToggleButtonGroup>
  );
}
