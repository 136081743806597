import { Typography } from '@mui/material';
import React, { useContext } from 'react'
import AnnonceContext from "../../../../../contexts/AnnonceContext";

import Attribute from './Attribute';

export default function AttributeSelector({isSecret}) {
    const { currentCategory  } = useContext(AnnonceContext);
  return (
      <>
    {currentCategory !== undefined  && (
        <>
   
        <Typography variant="h4">{currentCategory.name}</Typography>
        <Attribute isSecret={isSecret}/>
        </>
        )}
        </>
  
    
  )
}
