import { Stepper, Step, StepLabel, Typography, Button, Paper } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import AnnonceContext from '../../../../contexts/AnnonceContext';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
//
export default function CustomStepper({ children }) {
  const { activeStep, setActiveStep, formAnnonce } = useContext(AnnonceContext);

  const status = formAnnonce.status.toLowerCase();
  const steps = ['Date et Lieu', `Objet ${status}`, 'Photo et détails', 'Infos secretes', 'Stockage'];
  const stepDescription = [
    `Quand ?`,
    `Quoi ?`,
    'Ajouter une photo',
    "Détails Secrets",
    "Stockage"
  ];
  const stepComponent = [<Step1 />, <Step2 />, <Step3 />, <Step4 />, <Step5/>];
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  return (
    <div>
      <Paper
        sx={{
          minHeight: 600,
          padding: 5,
        }}
      >
        <Stepper activeStep={activeStep}>
          {steps.map((step, i) => (
            <Step key={i}>
              <StepLabel>{step}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <Typography variant="h4" sx={{ marginTop: 5, marginBottom: 5 }}>
          {stepDescription[activeStep]}
        </Typography>
        {stepComponent[activeStep]}

        {children}
      </Paper>
      <Button onClick={handleBack} disabled={activeStep === 0} variant="contained">
        Précédent
      </Button>
      <Button onClick={handleNext} disabled={activeStep === steps.length - 1} variant="contained">
        Suivant
      </Button>
    </div>
  );
}
