import {
  Avatar,
  Divider,
  Fab,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
  Paper,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@mui/material';
import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import SendIcon from '@mui/icons-material/Send';
import './Chat.css';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { fDateTime } from '../../utils/formatTime';

export default function Chat({ annonce }) {
  const navigate = useNavigate();
  const [action, setAction] = useState('');
  const [handleOnAction, setHandleOnAction] = useState('');
  const [open, setOpen] = useState(false);
  const [conversations, setConversations] = useState([]);
  const [currentConversation, setCurrentConversation] = useState([]);
  const [currentMessages, setCurrentMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [notOkMessage, setNotokMessage] = useState(
    `BONJOUR ! Nous sommes désolé mais vos description et information d'identification ne correspondent pas à l'objet trouvé concerné ! D'autres objets trouvés seront mis en ligne prochainement donc consultez régulièrement Sherlook !  A très vite !`
  );
  const [okMessage, setOkMessage] = useState(
    'BONJOUR ! Votre description et information d\'identification correspondent bien à l\'objet trouvé concerné ! Pour le récupérer voici son numéro : ' + annonce.category.name + ' - ' + annonce.id + ' à communiquer au Stand Sherlook situé au Hall 5 pendant le festival ou RDV au siège des Trans situé au 10 rue Jean Guy le dimanche 11 décembre de 16h à 18h ou à partir de mardi 13 décembre de 14h à 18h et ce jusqu\'à vendredi 16 décembre. Sinon il faudra attendre la rentrée le 2 janvier 2023.\nSi vous n\'êtes pas sur Rennes nous pouvons l\'envoyer par la poste nous vous demandons d\'avancer les frais d\'envoi. \nDans ce cas merci de contacter solenn.perdoux@lestrans.com\nMerci de votre compréhension !\n'
  );
  const [isSend, setIsSend] = useState(false);
  const [conversationKey, setConversationKey] = useState(false);
  const { id, title, filePath, status, annonceAttributes, category, stockage, date } = annonce;
  const boxName = null !== stockage ? stockage.boxName : 'pas de stockage';
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [restituLink, setRestituLink] = useState();

  const ShButton = styled(Button)`
    background-color: #1b9098;
    color: #fff;
    width: 100%;
    text-transform: uppercase;
    padding: 6px 12px;
    &:hover {
      background-color: #85c4c8;
    }
  `;
  useEffect(() => {
    fetchConversations();
  }, [message, isSend]);

  useEffect(() => {
    const id = setInterval(fetchConversations, 5000);
    return () => clearInterval(id);
  }, [conversationKey]);

  const fetchConversations = async () => {
    try {
      const data = await axios
        .get(`${process.env.REACT_APP_PUBLIC_API}/conversations?annonce=${annonce.id}`)
        .then((response) => response.data);

      setConversations(data);
      setCurrentConversation(conversationKey ? data[conversationKey] : data[0]);
      setCurrentMessages(
        conversationKey
          ? data[conversationKey].messages.sort((x, y) => x.id - y.id)
          : data[0].messages.sort((x, y) => x.id - y.id)
      );
    } catch (error) {
      console.error(error);
    }
  };
  const handleConversation = (conversation, index) => {
    setCurrentConversation(conversation);
    setCurrentMessages(conversation.messages);
    setConversationKey(index);
    setSelectedIndex(index);
  };

  const handleMessageNotOk = async () => {
    const data = {
      conversation: `api/conversations/${currentConversation.id}`,
      content: notOkMessage,
    };

    try {
      await axios.post(process.env.REACT_APP_PUBLIC_API + '/messages', data);
      setIsSend(!isSend);
    } catch (error) {
      console.error(error);
    }
  };

  const handleResponded = async () => {
    const url = `${process.env.REACT_APP_PUBLIC_API}/conversations/${currentConversation.id}`;
    const data = {
      isPending: false,
    };
    try {
      await axios.put(url, data).then((data) => {
        alert('répondu', currentConversation.id);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleNotResponded = async () => {
    const url = `${process.env.REACT_APP_PUBLIC_API}/conversations/${currentConversation.id}`;
    console.log(url);
    const data = {
      isPending: true,
    };
    try {
      await axios.put(url, data).then((data) => {
        alert('non répondu');
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleMessageOk = async () => {
    const data = {
      conversation: `api/conversations/${currentConversation.id}`,
      content: okMessage,
    };

    try {
      await axios.post(process.env.REACT_APP_PUBLIC_API + '/messages', data);
      setIsSend(!isSend);
    } catch (error) {
      console.error(error);
    }
  };

  const handleMessageLink = async () => {
    const messageLink = `https://www.sherlook.fr/elements/ca/v2livraison.php?o=${annonce.id}&token=${currentConversation.author.tokenValidation}`;
    const data = {
      conversation: `api/conversations/${currentConversation.id}`,
      content: messageLink,
      isRead: true,
    };
    const annonceData = {
      userExpedition: `api/users/${currentConversation.author.id}`,
    };
    console.log(annonceData);
    try {
      await axios
        .put(`${process.env.REACT_APP_PUBLIC_API}/annonces/${annonce.id}`, annonceData)
        .then(await axios.post(process.env.REACT_APP_PUBLIC_API + '/messages', data));

      setIsSend(!isSend);
    } catch (error) {
      console.error(error);
    }

    try {
      await axios.put(`${process.env.REACT_APP_PUBLIC_API}/annonces/${annonce.id}`, annonceData);
    } catch (error) {
      console.error(error);
    }
  };
  const handleMessage = async () => {
    if (message !== '') {
      const data = {
        conversation: `api/conversations/${currentConversation.id}`,
        content: message,
      };

      try {
        await axios.post(process.env.REACT_APP_PUBLIC_API + '/messages', data);
        setMessage('');
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleExpedition = async () => {
    let data = {
      status: 'A_EXPEDIER',
    };
    setIsLoading(true);
    try {
      await axios.put(`${process.env.REACT_APP_PUBLIC_API}/annonces/${annonce.id}`, data).then((data) => {
        setMessage('');
        setHandleOnAction('');
        setAction('');
        setActionSentence('');
        setActionMessage('');
        alert("objet envoyé à l'expédition");
        navigate(`/dashboard/products/aexpedier`);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleAnnulExpedition = async () => {
    let data = {
      status: annonce.origin,
    };

    setIsLoading(true);
    try {
      await axios.put(`${process.env.REACT_APP_PUBLIC_API}/annonces/${annonce.id}`, data).then((data) => {
        setMessage('');
        setHandleOnAction('');
        setAction('');
        setActionSentence('');
        setActionMessage('');
        alert('envoi annulé');
        navigate(`/dashboard/products/trouves`);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleRestitution = async () => {
    let data = {
      status: 'RESTITUÉ',
    };
    setIsLoading(true);
    try {
      await axios.put(`${process.env.REACT_APP_PUBLIC_API}/annonces/${annonce.id}`, data).then((data) => {
        setMessage('');
        setHandleOnAction('');
        setAction('');
        setActionSentence('');
        setActionMessage('');
        alert('objet restitué');
        navigate(`/dashboard/products/restitues`);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleAnnulRestitution = async () => {
    let data = {
      status: annonce.origin,
    };
    try {
      await axios.put(`${process.env.REACT_APP_PUBLIC_API}/annonces/${annonce.id}`, data);
      setMessage('');

      setHandleOnAction('');
      setAction('');
      setActionSentence('');
      setActionMessage('');

      navigate(`/dashboard/products/trouves`);
    } catch (error) {
      console.error(error);
    }
  };

  const [actionSentence, setActionSentence] = useState('');
  const [actionMessage, setActionMessage] = useState('');

  const handleClickOpen = (action) => {
    switch (action) {
      case 'lien':
        setActionSentence(`Voulez vous envoyer un lien a de restitution à ${currentConversation.author.email} : `);
        setActionMessage(
          "Cette opération est irrémédiable et permettra à la personne d'acceder au formulaire de restitution"
        );
        setAction('Envoyer un lien');
        setHandleOnAction('lien');
        setOpen(true);
        break;
      case 'restitution':
        setActionSentence(`Voulez vous restituer cet Objet : ${annonce.category.name} ${annonce.id}`);
        setActionMessage('L\'objet sera disponible dans la sections "Objets restitués" ');
        setAction('Restituer');
        setHandleOnAction('restitution');
        setOpen(true);
        break;
      case 'annulRestitution':
        setActionSentence(`Voulez vous annuler la restitution pour l' objet : ${annonce.category.name} ${annonce.id}`);
        setActionMessage(`L'objet sera disponible dans la section "objets ${annonce.origin.toLowerCase()}s"`);
        setAction('Annuler la restitution');
        setHandleOnAction('annulRestitution');
        setOpen(true);
        break;
      case 'expedition':
        setActionSentence(`Voulez vous envoyer en expédition l' objet : ${annonce.category.name} ${annonce.id}`);
        setActionMessage(`L'objet sera disponible dans la section "objets à expédier"`);
        setAction('A expedier !');
        setHandleOnAction('expedition');
        setOpen(true);
        break;

      case 'annulExpedition':
        setActionSentence(`Voulez vous annuler expédition ?`);
        setActionMessage(`L'objet sera disponible dans la section "objets Trouvés"`);
        setAction("Annuler l'expédition !");
        setHandleOnAction('annulExpedition');
        setOpen(true);
        break;

      default:
        return;
    }
  };

  const handleAction = () => {
    switch (handleOnAction) {
      case 'restitution':
        handleRestitution();
        setOpen(false);

        break;
      case 'annulRestitution':
        handleAnnulRestitution();
        setOpen(false);
        break;
      case 'expedition':
        handleExpedition();
        setOpen(false);
        break;
      case 'annulExpedition':
        handleAnnulExpedition();
        setOpen(false);
        break;
      case 'lien':
        handleMessageLink();
        setOpen(false);
        break;
      default:
        return;
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box pr={5} pt={5}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="body2"></Typography>
        </Grid>
      </Grid>

      <Grid
        container
        component={Paper}
        elevation={3}
        sx={{
          width: '100%',
        }}
      >
        <Grid
          item
          xs={3}
          md={4}
          sx={{
            backgroundColor: '#98DEEB',
            borderRight: '1px solid #e0e0e0',
          }}
        >
          <List>
            {/* bouclage des conversations */}
            <Typography variant="body1" p={1}>
              {' '}
              liste des utilisateurs connectés à cette annonce
            </Typography>
            <Divider />
            {conversations.length > 0 &&
              conversations.map((conversation, index) => (
                <ListItem
                  selected={selectedIndex === index}
                  button
                  key={conversation.id}
                  onClick={() => handleConversation(conversation, index)}
                >
                  <ListItemIcon>
                    <Avatar
                      alt=""
                      //    src={`${process.env.REACT_APP_PUBLIC_URL}/images/annonces/${conversation.annonce.filePath}`}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <>
                        <Typography
                          sx={{ display: 'inline', fontSize: '13px', overflowWrap: 'break-word' }}
                          component="span"
                          color="text.primary"
                        >
                          {conversation.author.email}
                        </Typography>
                      </>
                    }
                  />
                  {/* <ListItemText secondary="online" align="right"></ListItemText> */}
                </ListItem>
              ))}

            {/* fin bouclage des conversations */}
          </List>
        </Grid>

        <Grid item xs={8} md={8}>
          <List sx={{ overflowY: 'auto' }}>
            <ListItem key="0">
              <Grid container>
                <Grid item xs={12}>
                  <ListItemText align="right" secondary=""></ListItemText>
                </Grid>
              </Grid>
            </ListItem>

            {/* debut des message */}

            {currentMessages.length > 0 &&
              currentMessages.map((message) => (
                <ListItem key={message.id}>
                  <Grid container>
                    <Grid item xs={12}>
                      <ListItemText
                        className={message.author ? 'left' : 'right '}
                        align={message.author ? 'left' : 'right '}
                        primary={message.content}
                      ></ListItemText>
                    </Grid>
                    <Grid item xs={12}>
                      <ListItemText
                        align={message.author ? 'left' : 'right '}
                        secondary={fDateTime(message.createdAt)}
                      ></ListItemText>
                    </Grid>
                  </Grid>
                </ListItem>
              ))}
            {/* <div ref={messagesEndRef}></div>  */}

            {/* fin des message */}
          </List>
        </Grid>

        <Divider />
        <Grid container style={{ padding: '20px' }}>
          <Grid item xs={11}>
            <TextField
              autoComplete="off"
              id="outlined-basic-email"
              label="Votre message ..."
              value={message}
              fullWidth
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={1} align="right">
            <Fab color="#ccc" aria-label="add">
              <SendIcon onClick={handleMessage} />
            </Fab>
          </Grid>
        </Grid>
      </Grid>

      <Grid container>
        {/*<Grid item xs={6} p={1}>
          <ShButton
            variant="contained"
            size="large"
            onClick={handleResponded}
            sx={{
              backgroundColor: 'green',
            }}
          >
            Marquer comme Répondu{' '}
          </ShButton>
        </Grid>
        <Grid item xs={6} p={1}>
          <ShButton
            variant="contained"
            size="large"
            onClick={handleNotResponded}
            sx={{
              backgroundColor: 'red',
            }}
          >
            Marquer comme non Répondu{' '}
          </ShButton>
        </Grid>*/}
        <Grid item xs={6} p={1}>
          <ShButton
            variant="contained"
            size="large"
            onClick={handleMessageOk}
            sx={{
              backgroundColor: 'green',
            }}
          >
            Je valide{' '}
          </ShButton>
        </Grid>
        <Grid item xs={6} p={1}>
          <ShButton
            variant="contained"
            size="large"
            onClick={handleMessageNotOk}
            sx={{
              backgroundColor: 'red',
            }}
          >
            Je ne valide pas{' '}
          </ShButton>
        </Grid>
      </Grid>
      <Grid container>
        {/* boutons expe
        <Grid item xs={12} p={1}>
          {annonce.status !== 'A_EXPEDIER' && (
            <ShButton
              variant="contained"
              size="large"
              sx={{
                width: '100%',
              }}
              onClick={() => handleClickOpen('expedition')}
            >
              A EXPÉDIER
            </ShButton>
          )}
          {annonce.status === 'A_EXPEDIER' && (
            <ShButton
              sx={{
                backgroundColor: 'orange',
                width: '100%',
              }}
              variant="contained"
              size="large"
              onClick={() => handleClickOpen('annulExpedition')}
            >
              ANNULER L' EXPÉDITION !
            </ShButton>
          )}
        </Grid>
        <Grid item xs={12} p={1}>
          <ShButton
            variant="contained"
            size="large"
            sx={{
              width: '100%',
            }}
            onClick={() => handleClickOpen('lien')}
          >
            envoyer un lien de restitution
          </ShButton>
        </Grid>*/}
        <Grid item xs={12} p={1}>
          {annonce.status !== 'RESTITUÉ' && (
            <ShButton
              variant="contained"
              size="large"
              sx={{
                width: '100%',
              }}
              onClick={() => handleClickOpen('restitution')}
            >
              Je Restitue en main propre
            </ShButton>
          )}
          {annonce.status === 'RESTITUÉ' && (
            <ShButton
              sx={{
                backgroundColor: 'orange',
                width: '100%',
              }}
              variant="contained"
              size="large"
              onClick={() => handleClickOpen('annulRestitution')}
            >
              ANNULER LA RESTITUTION !
            </ShButton>
          )}
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{actionSentence}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{actionMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose} autoFocus>
            ne rien faire
          </Button>
          <Button variant="contained" onClick={handleAction}>
            {action}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
