import React, { useEffect, useState } from 'react';
import { Box, Grid, Button, Dialog, Badge, Typography, Stack } from '@mui/material';

import axios from 'axios';
import { AddBoxDialog } from './AddBoxDialog';
import { CategoriesBox } from './CategoriesBox';
export const Stockage = () => {
  const [stockage, setStockage] = useState([]);

  const [addBox, setAddBox] = useState();
  const baseUrl = process.env.REACT_APP_BASEURL;
  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_PUBLIC_API}/stockages?motherBox=1`;

    axios.get(apiUrl).then((resp) => {
      setStockage(resp.data);
    });
  }, [addBox]);

  return (
    <div>
      <Typography variant="h3" pb={5}>
        Stockage
      </Typography>

      <AddBoxDialog buttonText="+ Boîte" addBox={addBox} setAddBox={setAddBox} />
      <Grid container spacing={2} pt={5}>
        {stockage.map((data) => {
          const stockedObjects = (data.annonces) ? data.annonces.filter((a) => {
            return  a.status === 'TROUVÉ'
          }) : [];
        
          return (
            <Grid key={data.id} item xs={2} md={2}>
              <Badge badgeContent={stockedObjects.length} color="error">
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 100,
                    height: 100,

                    backgroundImage: `url(${baseUrl + '/pictos/box.svg'})`,
                    backgroundSize: 'cover',
                    '&:hover': {
                      opacity: [0.9, 0.8, 0.7],
                    },
                  }}
                ></Box>{' '}
              </Badge>
              <Typography color="success" align="left">
                {data.boxName}
              </Typography>

              {data.stockages.length > 0 && <CategoriesBox data={data.stockages} />}
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};
export default Stockage;
